
import { mapMutations, mapState } from 'vuex'
import hasModel from '@/mixins/hasModel'
export default {
    mixins: [hasModel],

    props: {
        isCard: Boolean,
        saveLabel: {
            type: String,
            default: 'Save',
        },
        savedLabel: {
            type: String,
            default: 'Saved',
        },
        newStyle: Boolean,
        label: {
            type: String,
            default: undefined,
        },
        defaultSavedLabel: {
            type: String,
            default: undefined,
        },
        signinLabel: {
            type: String,
            default: undefined,
        },
        noBtnClasses: Boolean,
        isPosse: Boolean,

        classes: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            page: 1,
            perPage: 4,
            search: null,
            selectedItems: [],
            computedSavedLabel: null,
        }
    },

    computed: {
        ...mapState({
            boards: (state) => state.board.simple.boards,
            items: (state) => state.board.simple.items,
            vendors: (state) => state.board.simple.vendors,
            posseUser: (state) => state.user?.posse?.user || {},
        }),

        isSaved() {
            if (this.isInspiration) return this.boards.some((el) => el.items.findIndex((x) => x === this.model.id) !== -1)
            else if (this.isVisionBoard || !this.contentType)
                return (
                    this.boards.findIndex((el) => {
                        return el.name === this.model.name
                    }) !== -1
                )
            else if (this.contentType === 'vendors') return this.vendors.findIndex((el) => el.vendor_id === this.model.id) !== -1
            else return this.items.findIndex((el) => el === this.model.id) !== -1
        },

        contentType() {
            return this.model && this.model.content_type ? this.model.content_type.slug : undefined
        },

        contentTypeName() {
            switch (this.contentType) {
                case 'vendors':
                    return 'Vendor'

                case 'wedding-inspiration':
                case 'media':
                    return 'Image'

                case 'vision-boards':
                    return 'Board'

                default:
                    return 'Item'
            }
        },

        contentName() {
            return this.model && this.model.name ? this.model.name : ''
        },

        buttonClasses() {
            if (this.noBtnClasses) return this.classes
            const buttonType = this.isCard ? 'btn-white' : this.isSaved ? 'btn-saved' : 'btn-white'
            return 'btn no-border btn-rounded ' + buttonType
        },

        isInspiration() {
            return this.contentType === 'media' || this.contentType === 'wedding-inspiration'
        },

        isVisionBoard() {
            return this.contentType === 'vision-boards'
        },

        paginatedBoards() {
            return this.boards.length
                ? [...this.boards]
                      .filter((el) => {
                          return !this.search || el.name.toLowerCase().includes(this.search.toLowerCase())
                      })
                      .filter((el, i) => {
                          return i < this.page * this.perPage
                      })
                : []
        },
    },

    watch: {
        $auth: {
            immediate: true,
            handler(val) {
                if (process.browser) if (val.loggedIn) this.$store.dispatch('board/fetchBoardsList')
            },
        },

        search() {
            this.page = 1
        },

        boards: {
            handler() {
                this.setSelectedItems()
            },
            immediate: true,
        },

        defaultSavedLabel: {
            handler() {
                this.computedSavedLabel = this.defaultSavedLabel ? this.defaultSavedLabel : this.label
            },
            immediate: true,
        },
    },

    mounted() {
        this.$nuxt.$on('dd-close', (el) => {
            if (this.$refs.saveButtonDd?.$el === el) {
                this.setSelectedItems()
                this.search = null
            }
        })
    },

    methods: {
        ...mapMutations({
            triggerBoardModal: 'board/triggerBoardModal',
            refreshBoards: 'board/boardListFetch',
        }),

        saveItem() {
            if ((this.isVisionBoard || !this.contentType) && (this.model.taxonomy || this.model.items)) {
                if (this.isSaved) return
                this.$toast.info(this.contentType ? 'Saving Mood Board...' : 'Saving Board...').goAway(3000)
                if (!this.isPosse) {
                    this.$store
                        .dispatch(`board/createBoard`, {
                            name: this.model.name,
                            is_private: false,
                            excerpt: this.model.excerpt,
                            items: this.model.taxonomy
                                ? this.model.taxonomy.map((el) => {
                                      return el.id
                                  })
                                : this.model.items.map((el) => {
                                      return el.id
                                  }),
                        })
                        .then(() => {
                            this.$toast
                                .success(this.contentType ? 'Mood board is successfully saved to your boards' : 'Board is successfully saved')
                                .goAway(3000)
                        })
                } else {
                    this.$axios
                        .$post(`/api/v1/user-posses/user/${this.posseUser.id}/boards`, {
                            name: this.model.name,
                            is_private: false,
                            excerpt: this.model.excerpt,
                            items: this.model.taxonomy
                                ? this.model.taxonomy.map((el) => {
                                      return el.id
                                  })
                                : this.model.items.map((el) => {
                                      return el.id
                                  }),
                            guest_wedding_event_id: this.$route.params.id || undefined,
                        })
                        .then(() => {
                            this.$toast
                                .success(this.contentType ? 'Mood board is successfully saved to your boards' : 'Board is successfully saved')
                                .goAway(3000)
                            this.$emit('fresh')
                        })
                }
            } else {
                if (this.contentType === 'vendors')
                    if (!this.isSaved)
                        this.$axios.$post('/api/v1/user-vendors', { vendor_id: this.model.id }).then((res) => {
                            this.$toast.success(`${this.model.name}'s profile has been added to your favorites.`).goAway(3000)
                            this.refreshBoards(false)
                            this.$store.dispatch('board/fetchBoardsList')
                        })
                    else {
                        const modelId = this.vendors.find((el) => el.vendor_id === this.model.id)?.id
                        this.$axios.$delete(`/api/v1/user-vendors/${modelId}`).then(() => {
                            this.$toast.success('Vendor is successfully removed').goAway(3000)
                            this.refreshBoards(false)
                            this.$store.dispatch('board/fetchBoardsList')
                        })
                    }
                else
                    this.$store.dispatch('board/saveItem', this.model).then(() => {
                        if (this.isSaved)
                            this.$toast
                                .show(
                                    this.contentName !== '' ? `${this.contentTypeName} ${this.contentName} was saved.` : `${this.contentTypeName} was saved.`,
                                    {
                                        className: 'saved-item-toast',
                                    }
                                )
                                .goAway(3000)
                    })
            }
        },

        createBoard() {
            this.$store.dispatch('board/setItem', this.model).then(() => {
                this.triggerBoardModal({})
            })
            this.$nextTick(() => {
                this.$refs.saveButtonDd.close()
            })
        },

        saveItemToBoard(board = null) {
            if (!board) return

            const idx = this.selectedItems.findIndex((el) => {
                return el.board === board
            })

            if (idx !== -1) {
                this.selectedItems[idx].isActive = !this.selectedItems[idx].isActive
            } else
                this.selectedItems.push({
                    board,
                    isActive: true,
                    isNew: true,
                })
        },

        hasItemInBoard(board) {
            const item = this.selectedItems.find((el) => {
                return el.board === board.slug
            })
            return item?.isActive
        },

        handleIntersect() {
            this.page++
        },

        setSelectedItems() {
            this.selectedItems = []
            this.boards.map((el) => {
                if (el.items.includes(this.model.id))
                    this.selectedItems.push({
                        board: el.slug,
                        isActive: true,
                    })
            })
        },

        saveItems() {
            this.$store.dispatch('board/saveItemToBoard', {
                model: this.model,
                boards: this.selectedItems
                    .filter((el) => {
                        return (el.isNew && el.isActive) || (!el.isNew && !el.isActive)
                    })
                    .map((el) => {
                        return el.board
                    }),
            })

            this.$refs.saveButtonDd.close()
        },
    },
}
